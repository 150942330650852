export const SUPPORTED_NFTS = [
  {name: "ENS Maxis",         contractAddress: "0xaa462106dA447C0440a4bE29614c19387a59A331"},
  {name: "Cryptopunk",        contractAddress: "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb"},
  {name: "BAYC",              contractAddress: "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d"},
  {name: "MAYC",              contractAddress: "0x60e4d786628fea6478f785a6d7e704777c86a7c6"},
  {name: "N Project",         contractAddress: "0x05a46f1e545526fb803ff974c790acea34d1f2d6"},
  {name: "Crypto Ape",        contractAddress: "0x29714cafe792ef8b8c649451d13c89e21a0d7f5b"},
  {name: "Moonbird",          contractAddress: "0x23581767a106ae21c074b2276d25e5c3e136a68b"},
  {name: "Goopdood",          contractAddress: "0x2dff22dcb59d6729ed543188033ce102f14ef0d1"},
  {name: "Tiny Dino",         contractAddress: "0xd9b78a2f1dafc8bb9c60961790d2beefebee56f4"},
  {name: "Animeta",           contractAddress: "0x18df6c571f6fe9283b87f910e41dc5c8b77b7da5"},
  {name: "Developer DAO",     contractAddress: "0x25ed58c027921e14d86380ea2646e3a1b5c55a8b"},
  {name: "Chainrunner",       contractAddress: "0x97597002980134bea46250aa0510c9b90d87a587"},
  {name: "DeadFellaz",        contractAddress: "0x2acab3dea77832c09420663b0e1cb386031ba17b"}, // CORS Issue
  {name: "Cool Cats",         contractAddress: "0x1a92f7381b9f03921564a437210bb9396471050c"},
  {name: "Crypto Coven",      contractAddress: "0x5180db8f5c931aae63c74266b211f580155ecac8"},
  {name: "Meebits",           contractAddress: "0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7"},
  {name: "Invisble Friends",  contractAddress: "0x59468516a8259058bad1ca5f8f4bff190d30e066"},
  {name: "Azuki",             contractAddress: "0xed5af388653567af2f388e6224dc7c4b3241c544"},
  {name: "Veefriends",        contractAddress: "0xa3aee8bce55beea1951ef834b99f3ac60d1abeeb"},
  {name: "Veefriends v2",     contractAddress: "0x9378368ba6b85c1fba5b131b530f5f5bedf21a18"},
  {name: "Imaginary Ones",    contractAddress: "0x716f29b8972d551294d9e02b3eb0fc1107fbf4aa"},
  {name: "Milady Maker",      contractAddress: "0x5af0d9827e0c53e4799bb226655a1de152a425a5"},
  {name: "Clone X",           contractAddress: "0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b"},
  {name: "Doodles",           contractAddress: "0x8a90cab2b38dba80c64b7734e58ee1db38b8992e"},
  {name: "World of Women",    contractAddress: "0xe785e82358879f061bc3dcac6f0444462d4b5330"},
  {name: "WoW Galaxy",        contractAddress: "0xf61f24c2d93bf2de187546b14425bf631f28d6dc"},
  {name: "mfers",             contractAddress: "0x79fcdef22feed20eddacbb2587640e45491b757f"},
  {name: "alien frens",       contractAddress: "0x123b30e25973fecd8354dd5f41cc45a3065ef88c"},
  {name: "BAKC",              contractAddress: "0xba30e5f9bb24caa003e9f2f0497ad287fdf95623"},
  {name: "Friendship Bracelets",    contractAddress: "0x942bc2d3e7a589fe5bd4a5c6ef9727dfd82f5c8a"},
  {name: "Loot",              contractAddress: "0xff9c1b15b16263c61d017ee9f65c50e4ae0113d7"},
  {name: "CryptoKitties",     contractAddress: "0x06012c8cf97bead5deae237070f9587f8e7a266d"},
  {name: "CrypToadz",         contractAddress: "0x1cb1a5e65610aeff2551a50f76a87a7d3fb649c6"},
  {name: "CyberKongz",        contractAddress: "0x57a204aa1042f6e66dd7730813f4024114d74f37"},
  {name: "Pudgy Penguins",    contractAddress: "0xbd3531da5cf5857e7cfaa92426877b022e612cf8"},
  {name: "0N1 Force",         contractAddress: "0x3bf2922f4520a8ba0c2efc3d2a1539678dad5e9d"},
  {name: "Mekaverse",         contractAddress: "0x9a534628b4062e123ce7ee2222ec20b86e16ca8f"},
  {name: "Hashmasks",         contractAddress: "0xc2c747e0f7004f9e8817db2ca4997657a7746928"},
  {name: "Creature World",    contractAddress: "0xc92ceddfb8dd984a89fb494c376f9a48b999aafc"},
  {name: "FLUF World",        contractAddress: "0xccc441ac31f02cd96c153db6fd5fe0a2f4e6a68d"},
  {name: "Gh0stly Gh0sts",    contractAddress: "0xa74ae2c6fca0cedbaef30a8ceef834b247186bcf"},
  {name: "BGAN Punks",        contractAddress: "0x31385d3520bced94f77aae104b406994d8f2168c"},
]